export const socialData = [
  {
    type: "youtube",
    link: "https://www.youtube.com/c/AgustinNavarroGaldon?sub_confirmation=1",
  },
  {
    type: "twitter",
    link: "https://twitter.com/xAgustin93",
  },
  {
    type: "facebook",
    link: "https://www.facebook.com/AgustinNavarroGaldon",
  },
  {
    type: "linkedin",
    link: "https://www.linkedin.com/in/agustin93?originalSubdomain=es",
  },
];
