export const itemsData = [
  {
    icon: "clock outline",
    title: "Cursos y Clases",
    description:
      "Cursos de entre 10 y 30 horas y cada clase del curso con duración máxima de 15 minutos.",
  },
  {
    icon: "key",
    title: "Acceso 24/7",
    description:
      "Accede a los cursos en cualquier momento, desde cualquier lugar sin importar día y hora.",
  },
  {
    icon: "comment alternate outline",
    title: "Aprendizaje colaboratico",
    description:
      "Aprende de los demás dejando tus dudas para que profesores y compañeros te ayuden.",
  },
  {
    icon: "user circle outline",
    title: "Mejora tu perfil",
    description:
      "Aprende y mejora tu perfil para mantenerte informado de actualizaciones.",
  },
  {
    icon: "dollar",
    title: "Precios bajos",
    description:
      "Obtén el curso que necesitas por solo 9.99 y ten acceso a el por tiempo ilimitado.",
  },
  {
    icon: "certificate",
    title: "Certificador de finalización",
    description: "Al completar tu un curso recibirás una certificación en PDF.",
  },
];
